import React from "react"
import Footer from "components/common/footer"
import * as styles from "./layout.module.css"
import Logo from "components/tools/logo.js"
import { navigate } from "gatsby-link"
const Layout = props => {
  return (
    <div>
      <div className={styles.header} id={"termHeader"}>
        <div>
          <div
            onClick={() => {
              navigate("/front")
            }}
          >
            <Logo w={121.94} h={26.85} />
          </div>
          {props?.menu?.map(([key, value]) => (
            <div
              data-id={key}
              key={key}
              className={styles.link}
              onClick={props.onMenu}
            >
              {value}
            </div>
          ))}
        </div>
      </div>
      <div />
      {props.children}
      <Footer />
    </div>
  )
}

export default Layout
