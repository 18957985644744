import React, { useState, useEffect } from "react"
import Layaut from "./layout"
import * as styles from "./index.module.css"
import { term } from "utils/data.js"
import api from "apis"
import { getDateYMD } from "utils"
import Logo from "components/tools/logo.js"
import dompurify from "dompurify"
import queryString from "query-string"
import { navigate } from "gatsby"

const Index = props => {
  const menu = Array.from(term).filter(([key]) => key !== 7 && key !== 8)
  const [terms, setTerms] = useState({})

  const { number } = queryString.parse(props.location.search)

  useEffect(() => {
    ;(async () => {
      // await setting(term.keys().next().value, term, setTerms)
      await setting(number ?? term.keys().next().value, term, setTerms)
    })()
  }, [number])

  const onMenu = async ({
    target: {
      dataset: { id },
    },
  }) => {
    // await setting(id, term, setTerms)
    // console.log(id)
    // console.log(term)
    // console.log(term.get(Number(id)))

    navigate(`/term/?number=${id}`)
  }

  const onContentTitle = ({
    target: {
      dataset: { id },
    },
  }) => {
    window.scroll({
      top:
        document.getElementById(id).offsetTop -
        document.getElementById("termHeader").offsetHeight,
      behavior: "smooth",
    })
    // topOfElement.scrollIntoView()
  }
  console.log(terms, "hi")
  return (
    <Layaut menu={menu} onMenu={onMenu}>
      <div className={styles.con}>
        <div>
          <div className={styles.title}>{terms.title}</div>
          <div className={styles.time}>
            현행 시행일자:{terms.time}
            <hr />
          </div>
          <div>
            {term.forEach((value, key) => (
              <div key={key}>{value}</div>
            ))}
          </div>
          <div className={styles.contentTitle} onClick={onContentTitle}>
            {terms?.contentTitle?.map((title, i) => (
              <div
                key={i}
                data-id={`contentTitle_${i + 1}`}
                style={{ cursor: "pointer" }}
              >
                {i + 1}.{title}
              </div>
            ))}
          </div>
          <hr />
          <div className={styles.content}>
            {typeof window !== "undefined"
              ? terms?.content?.map((body, i) => (
                  <div key={i} id={`contentTitle_${i + 1}`}>
                    <div>
                      {i + 1}.{terms?.contentTitle[i]}
                    </div>
                    {/* <div>{body}</div> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(body),
                      }}
                    />
                  </div>
                ))
              : null}
          </div>
          <div>
            <Logo w={268.06} h={59.03} />
          </div>
        </div>
      </div>
    </Layaut>
  )
}

export default Index
import dayjs from "dayjs"
const setting = async (key, data, setTerms) => {
  const terms = await api.getTermDetail(key)

  let contentTitle = []
  let content = []

  if (terms?.length && terms[0].TermContent) {
    terms[0].TermContent.forEach(({ title, bodyText }) => {
      contentTitle.push(title)
      content.push(bodyText)
    })
  }

  if (terms?.length > 0) {
    const title = data.get(terms[0].kind) ?? ""
    // console.log(456, terms[0].title)
    // const title = terms[0].title
    const time = getDateYMD(terms[0]?.policy_begin_at, "YYYY년M월D일")
    console.log(terms, "hihi")
    setTerms({
      title,
      contentTitle,
      content,
      time,
    })
  } else {
    setTerms({
      title: "",
      contentTitle,
      content: [],
      time: "",
    })
  }
}
